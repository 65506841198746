import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
  }
`;
export default GlobalStyle;

export const AppContainer = styled.div`
  width: 88%;
  height: ${({ height }) => height || "100%"};
  margin:auto;
`;

export const BlackLayer = styled.div`
width:100%;
height:120vh;
position:absolute;
background: linear-gradient( 180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100% );
`;

export const FlexBox = styled.div`
display:flex;
`;