import React from "react";
import styled from "styled-components";
import { AppContainer } from "../../Styles/GobalStyle";
import { AttentionSeeker } from "react-awesome-reveal";
import {
    AiChatApp,
    DatingAndShotApp,
    FitnessApp,
    ResturentAndRealEsateApp,
} from "../../utils/ProjectPortfolioData";
import CustomVideoPlayer from "../../components/CustomVideoPlayer";

export default function ProjectContainer() {
    return (
        <ProjectContainerWrapper>
            <AppContainer>
                <div className="project-header">
                    <h4>Website, Web app, Mobile app and Ai Chat bots in one place</h4>
                    <h2>Our Portfolio</h2>
                </div>
                <SingleProject data={FitnessApp} isMulti={false} />
                <MultiProject data={ResturentAndRealEsateApp} isMulti={true} />
                <SingleProject data={AiChatApp} isMulti={false} />
                <MultiProject data={DatingAndShotApp} isMulti={true} />
            </AppContainer>
        </ProjectContainerWrapper>
    );
}

const SingleProject = ({ data, isMulti }) => {
    console.log("Video Source:", data);
    return (
        <SingleProjectWrapper isDisplayMulti={isMulti}>
            <AttentionSeeker effect="pulse" delay={1000}>
                {data?.image ? (
                    <img src={data?.image} alt="healthapp" />
                ) : (
                    <CustomVideoPlayer src={data?.video} />
                )}
            </AttentionSeeker>
            <p className="tech-used">Technology Used : {data?.techUsed}</p>
            <p className="description">
                <span>{data?.desHead}</span> - {data?.desCon}
            </p>
        </SingleProjectWrapper>
    );
};

const MultiProject = ({ data, isMulti }) => {
    return (
        <MultiProjectWrapper>
            {data?.map((p) => (
                <SingleProject data={p} isMulti={isMulti} />
            ))}
        </MultiProjectWrapper>
    );
};

const SingleProjectWrapper = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: ${({ isDisplayMulti }) => (isDisplayMulti ? "0" : "60px")};
  position: relative;
  z-index: 99;

  @media (max-width: 992px) {
    width: 100%;
    padding: 60px 0;
  }
    
  @media (max-width: 600px) {
    width: 100%;
    padding: 30px 0;
  }

  img {
    border-radius: 16px;
    filter: grayscale(1);
    cursor: crosshair;
    width: 100%;
    opacity: 0.4;

    @media (max-width: 600px) {
      filter: grayscale(0);
      opacity: 1;
    }

    &:hover {
      filter: grayscale(0);
      opacity: 1;
      transform: scale(0.95);
      transition: transform 0.4s;
    }
  }

  .tech-used {
    font-family: Inter Tight;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #ffffff;
    padding-bottom: 10px;
  }

  .description {
    font-family: Inter Tight;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #ffffff;

    span {
      font-family: Inter Tight;
      font-size: 24px;
      font-weight: 700;
      line-height: 150%;
      color: #f16136;
    }
  }
`;

const MultiProjectWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  gap: 250px;
  @media (max-width: 1200px) {
    gap: 100px;
  }
  @media (max-width: 992px) {
    gap: 40px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    gap: 0;
  }
`;

const ProjectContainerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 200px 0 40px 0;
  background: #111111;
  z-index: 9;
  @media (max-width: 664px) {
    padding: 80px 0 40px 0;
  }

  .project-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    h4 {
      font-family: Inter Tight;
      font-size: 20px;
      font-weight: 800;
      line-height: 24.2px;
      text-align: left;
      color: #f16136;
      @media (max-width: 664px) {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
      }
    }
    h2 {
      font-family: Bebas Neue;
      font-size: 100px;
      font-weight: 400;
      line-height: 100%;
      text-align: left;
      color: #ffffff;
      letter-spacing: 0.05em;
      @media (max-width: 664px) {
        font-size: 80px;
      }
    }
  }
`;
