import React from 'react'
import styled from 'styled-components';

export default function SplashWelcome() {
  return (
    <AminationWrapper>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </AminationWrapper>
  )
}

const AminationWrapper = styled.div`
  width: 100%;
  height: 120vh;
  display: flex;
  position:absolute;
  z-index:1;

  :nth-child(1) {
    width: 6%;
    height: 0;
    background: #fff;
    animation-name: up;
    animation-duration: 1.2s;
    @keyframes up {
      from {
        height: 120vh;
      }
      to {
        height: 0;
      }
    }
  }

  :nth-child(2) {
    width: 29.33%;
    height: 0;
    background: #fff;
    animation-name: up;
    animation-duration: 2.4s;
    @keyframes up {
      from {
        height: 120vh;
      }
      to {
        height: 0;
      }
    }
  }
  :nth-child(3) {
    width: 29.33%;
    height: 0;
    background: #fff;
    animation-name: up;
    animation-duration: 1.8s;
    @keyframes up {
      from {
        height: 120vh;
      }
      to {
        height: 0;
      }
    }
  }
  :nth-child(4) {
    width: 29.33%;
    height: 0;
    background: #fff;
    animation-name: up;
    animation-duration: 3.2s;
    @keyframes up {
      from {
        height: 120vh;
      }
      to {
        height: 0;
      }
    }
  }
  :nth-child(5) {
    width: 6%;
    height: 0;
    background: #fff;
    animation-name: up;
    animation-duration: 1.2s;
    @keyframes up {
      from {
        height: 120vh;
      }
      to {
        height: 0;
      }
    }
  }
`;