import React from "react";
import styled from "styled-components";
import { Github } from "../../utils/Images";

export default function CredentialBox() {
    return (
        <CredentialBoxWrapper>
            <div className="box-image">
                <img src={Github} alt="github" />
            </div>
            <div>
                <h1>GitHub</h1>
            </div>
        </CredentialBoxWrapper>
    );
}

const CredentialBoxWrapper = styled.div`
  position: relative;
  width: 398px;
  height: 211px;
  border-radius: 15px;
  background: #fdfdfd;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  padding: 30px;

  .box-image {
    position: absolute;
    width: 223px;
    height: 223px;
    right: -90px;
    border-radius: 50%;
    box-shadow: 11px 13px 17px rgb(0 0 0 / 15%);
    display:flex;
    align-items:center;
    justify-content:center;
    img {
        width: 200px;
        height: 200px; 
        
    }
  }
`;
