import React, { useEffect, useRef } from "react";
import MainLanding from "../pages/MainLanding";
import OurClients from "../pages/OurClients";
import OurService from "../pages/OurService";
import FindInspiration from "../pages/FindInspiration";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Footer from "../components/Footer";

export default function LandingLayout() {
    const servicesRef = useRef(null);
    const projectRef = useRef(null);
    const contactRef = useRef(null);

    return (
        <div>
            <MainLanding
                servicesRef={servicesRef}
                projectRef={projectRef}
                contactRef={contactRef}
            />
            <OurService servicesRef={servicesRef} />
            <OurClients />
            <FindInspiration projectRef={projectRef} />
            <AboutUs />
            <ContactUs contactRef={contactRef} />
            <Footer />
        </div>
    );
}
