import React from "react";
import styled from "styled-components";
import { AppLogo } from "../utils/Images";
import { useLocation } from "react-router-dom";
import { Arrow } from "../utils/Icons";

export default function AdminHeader() {
    const location = useLocation();
    return (
        <AdminHeaderWrapper>
            <RightSide>
                <h2>{location?.pathname?.replace("/private/", "")}</h2>
            </RightSide>
            <LeftSide>
                <img src={AppLogo} alt="" />
                <div className="logout">
                    <Arrow />
                </div>
            </LeftSide>
        </AdminHeaderWrapper>
    );
}

const RightSide = styled.div`
  h2 {
    font-family: Inter;
    font-size: 22px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #242220;
    text-transform: capitalize;
    letter-spacing: 1.4px;
  }
`;
const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  img {
    width: 112px;
  }

  .logout {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: #f8f4f2;
    border: 0.5px solid #f5efeb52;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(270deg);
    cursor: pointer;

    svg path {
        // fill:#662500ad;
        fill-opacity:0.6;
    }
  }
`;

const AdminHeaderWrapper = styled.div`
  width: calc(100% - 48px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  z-index: 9;
  background: #fff;
  border: 0.5px solid #f5efeb66;
  border-radius: 28px;
  box-shadow: 0px 64px 64px -32px #6625008f;
`;
