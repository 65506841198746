import React from "react";
import styled from "styled-components";
import { Arrow, Credentials, Dashboard, Requests } from "../utils/Icons";
import { useLocation, useNavigate } from "react-router-dom";
const tabs = [
  {
    name: "Dashboard",
    icon: <Dashboard />,
    path: "dashboard",
  },

  {
    name: "Requests",
    icon: <Requests />,
    path: "requests",
  },

  {
    name: "Credentials",
    icon: <Credentials />,
    path: "credentials",
  },
];

export default function AdminSidenav({ isMinimized, setIsMinimized }) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <AdminSidenavWrapper isMinimized={isMinimized}>
      <MinimizedSidebar isMinimized={isMinimized} onClick={() => setIsMinimized(!isMinimized)}>
        <Arrow />
      </MinimizedSidebar>
      <div className="profile-info">
        <section>JK</section>
        <div>
          <p>Admin Accesss</p>
          <h2>Jayant Kumar</h2>
        </div>
      </div>
      <DivideLine />
      {tabs &&
        tabs?.map((t) => (
          <Tab
            onClick={() => navigate(t?.path)}
            selectedPath={t?.path}
            isMinimized={isMinimized}
          >
            <div className={location?.pathname?.includes(t?.path) && "active"}>
              {t?.icon}
              <p>{t?.name}</p>
            </div>
          </Tab>
        ))}
    </AdminSidenavWrapper>
  );
}

const Tab = styled.div`

  div {
    width: auto;
    height: 56px;
    gap: 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: ${({ isMinimized }) => isMinimized && "center"};
    padding: ${({ isMinimized }) => !isMinimized && "0 22px"};
    cursor: pointer;
    color: #2422208f;
    margin: 16px 0;
    transition: all 1s;
    &:hover {
      background: #2422200a;
      border: 0.5px solid #f5efeb80;
    }
  }

  p {
    display: ${({ isMinimized }) => isMinimized && "none"};
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }

  .active {
    background: #2422200a;
    svg path {
      stroke: ${({ selectedPath }) =>
    selectedPath === "dashboard" && "#242220"};
      fill: ${({ selectedPath }) => selectedPath !== "dashboard" && "#242220"};
    }
    p {
      color: #242220;
    }
  }
`;

const AdminSidenavWrapper = styled.div`
  position: relative;
  width: calc(100% - 40px);
  height: calc(100vh - 80px);
  background: #fff;
  border: 0.5px solid #f5efeb66;
  border-radius: 28px;
  box-shadow: 0px 64px 64px -32px #6625008f;
  padding: 20px;

  .profile-info {
    display: flex;
    gap: 12px;
    section {
      width: 48px;
      height: 48px;
      gap: 0px;
      border-radius: 36.22px;
      background: #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Inter;
      font-size: 18px;
      font-weight: 400;
    }
    div {
      display: ${({ isMinimized }) => (isMinimized ? "none" : "flex")};
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        font-family: Inter;
        font-size: 11px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.4000000059604645px;
        color: #24222066;
        text-transform: uppercase;
        white-space:nowrap;
      }
      h2 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #242220;
        white-space:nowrap;
      }
    }
  }
`;

const DivideLine = styled.div`
  width: 100%;
  height: 0.5px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #432c2c 0%,
    rgba(80, 28, 28, 0) 100%
  );
  margin: 16px 0 32px 0;
`;

const MinimizedSidebar = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: #f8f4f2;
  border: 0.5px solid #f5efeb52;
  right: -13px;
  top: 31px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: ${({ isMinimized }) => isMinimized ? "rotate(180deg)" : "rotate(0deg)"} ;
  }
`;
