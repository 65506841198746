import React from "react";
import styled from "styled-components";
import { LetsTalk } from "../utils/Images";
import { AppContainer, BlackLayer } from "../Styles/GobalStyle";
import ContactForm from "../components/ContactForm";

export default function ContactUs({ contactRef }) {
  return (
    <ContactUsWrapper>
      <SeemsLikeBackground>
        <img src={LetsTalk} alt="let's talk" />
      </SeemsLikeBackground>
      <FirstSection>
        <BlackLayer style={{ height: "100vh" }} />
        <AppContainer>
          <ContactUsInnerWrapper ref={contactRef}>
            <header className="first-header">
              <p>Swaroop ordering coffee for us</p>
              Let's talk about you,
              <br /> your Product & Goals with coffee
            </header>
          </ContactUsInnerWrapper>
        </AppContainer>
      </FirstSection>

      <SecondSection>
        <main>
          <h1>
            Have questions about our project, analytics, or collaboration
            agreement? Fill out this form to connect with us.
          </h1>
          <h2>Get in touch</h2>
          <ContactForm />
          {/* <div>
            <iframe src="https://forms.visme.co/formsPlayer/mxgpjm13-untitled-project" width={"100%"} height={"900px"} style={{ border: "none" }} />
          </div> */}
        </main>
      </SecondSection>


    </ContactUsWrapper>
  );
}

const SecondSection = styled.div`
  width: 100%;
  height: 790px;
  background: #eef0f6;

  main {
    width: calc(100% - (6% + 76px));
    margin-left: 6%;
    margin-top: -55px;
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 40px 38px;
    display:flex;
    flex-direction:column;
    gap:20px;

    h1 {
      color: #6e7488;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    h2 {
      font-size: 48px;
      line-height: 1.2em;
      font-weight: 600;
      letter-spacing: -0.03em;
      @media (max-width: 500px) {
        font-size: 36px;
      }
    }
  }
`;

const ContactUsInnerWrapper = styled.div`
  position: relative;
  z-index: 8;
  height: calc(100vh - 89px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  header {
    color: #fff;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;

    @media(max-width:695px){
      font-size: 52px;
    }
    @media(max-width:580px){
      font-size: 42px;
    }
  }
  p {
    color: #ec6e25;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -0.02em;
  }
`;

const FirstSection = styled.div`
  width: 100%;
  height: 100vh;
  background-size: cover;
`;

const SeemsLikeBackground = styled.div`
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 89px;

  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
`;

const ContactUsWrapper = styled.div`
  width: 100%;
  height: 200vh;
  position: relative;
  background: #000;
`;
