export const securityChecks = () => {
    const handleContextMenu = (e) => {
        e.preventDefault();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'F12' || (e.ctrlKey && (e.key === 'i' || e.key === 'I')) || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
            e.preventDefault();
            e.stopImmediatePropagation();
        }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
        document.removeEventListener('keydown', handleKeyDown);
    };
}