import React from "react";
import styled from "styled-components";
import { AppContainer } from "../Styles/GobalStyle";
import { FooterLogo } from "../utils/Images";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  return (
    <FooterWrapper isMainLanding={location?.pathname == "/" ? true : false}>
      <AppContainer>
        <section className="inner-container">
          <img src={FooterLogo} alt="footer-logo" width={100} />
          <p>All copyright revised by swaroopitsolutions.com</p>
        </section>
      </AppContainer>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div`
  width: 100%;
  height: 58px;
  background: ${({ isMainLanding }) => isMainLanding ? "#fff" : "rgb(0, 0, 0, 0.2)"};
  position: relative;
  z-index: 99999;
  margin-top: ${({ isMainLanding }) => isMainLanding && "735px"};

  .inner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      text-align: end;
      font-family: Inter Tight;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      letter-spacing: 0.030em;
      color: #111111;

    }
  }
`;
