import React from "react";
import styled from "styled-components";
import { AppContainer } from "../Styles/GobalStyle";
import {
  FirebaseIcon,
  NestJSIcon,
  NextJSIcon,
  NodeJSIcon,
  ReactFirebaseIcon,
  ReactJSIcon,
} from "../utils/Icons";
import MotionButton from "../animations/MotionButton";

export default function OurService({ servicesRef }) {
  return (
    <OurServiceWrapper ref={servicesRef}>
      <AppContainer >
        <FirstSection>
          <section>
            <div>
              <p>Service</p>
              <h1>We do it best.</h1>
            </div>
            {/* <div className="position-motionBtn">
              <MotionButton
                tittle={"View all review"}
                baseColor={"#ffffff"}
                backgroundColor={"#000000"}
                hoverColor={"#e2e7f1"}
                texthoverColor={"#090b19"}
                handleClicked={()=>console.log('I am clicked')}
              />
            </div> */}
          </section>

          <main>
            <div>
              <ReactJSIcon />
              <span>react js</span>
            </div>
            <div>
              <NodeJSIcon />
              <span>node js</span>

            </div>
            <div>
              <FirebaseIcon />
              <span>firebase</span>

            </div>
            <div>
              <NestJSIcon />
              <span>Nest js</span>

            </div>
            <div>
              <ReactFirebaseIcon />
              <span>React firebase</span>
            </div>
            <div>
              <NextJSIcon />
              <span>next js</span>
            </div>
          </main>
        </FirstSection>
      </AppContainer>
    </OurServiceWrapper>
  );
}

const OurServiceWrapper = styled.div`
  width: 100%;
  // height:600px;
  background: #fff;
  // padding-bottom:20px;
   scroll-margin: 89px;
`;

const FirstSection = styled.div`
  // width:100%;
  background-color: #f3f6fc;
  padding: 60px 40px;
  @media (max-width: 350px) {
    padding: 60px 20px;
  }

  main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 60px 0 0 0;
    gap: 40px;

    @media(max-width:650px){
      grid-template-columns: repeat(2, 1fr);
    }
    @media(max-width:450px){
      grid-template-columns: repeat(1, 1fr);
    }

    div {
      width: 100%;
      // height:120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        padding: 15px 0 0 0;
        width: fit-content;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 4px;
        text-transform: uppercase;
      }

      svg {
        width: 120px;
        height: 120px;
        &:hover {
          transform: rotate(360deg) scale(1.2);
          transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }
      }
    }
  }

  section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      width:100%;
      display: flex;
      flex-direction: column;
      gap: 10px;

      > p {
        color: #6e7488;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      
      }
      > h1 {
        font-size: 48px;
        line-height: 1.2em;
        font-weight: 600;
        letter-spacing: -0.03em;

        @media(max-width:550px){
          font-size: 36px;
        }
      }
      @media (max-width: 450px) {
        text-align: center;
      }
    }
  }
`;
