import { Form, Input } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import MotionButton from "../../animations/MotionButton";

export default function Login() {
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        // setLoading(true);
        // let req = {
        //     "name": values?.firstname + " " + values?.lastname,
        //     "email": values?.email,
        //     "phoneNumber": "9811956722",
        //     "description": values?.message
        // }
        // let res = await createContact(req);
        // if (res?.status === 200) {
        //     openNotification('top', "Request submitted successfully! Swaroop will contact you back soon")
        //     setLoading(false)
        // } else {

        // }
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <LoginWrapper>
            <main>
                <h1>Admin Login</h1>
                <Form
                    name="basic"
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <section>
                        <label>Email Address</label>
                        <div className="inner-wrapper">
                            <Form.Item
                                label=""
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your email!",
                                    },
                                    {
                                        type: "email",
                                        message: "The input is not valid E-mail!",
                                    },
                                ]}
                            >
                                <Input prefixCls="input" placeholder="Your email" />
                            </Form.Item>
                        </div>
                    </section>

                    <section>
                        <label>password</label>
                        <div className="inner-wrapper">
                            <Form.Item
                                label=""
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your password!",
                                    },
                                    {
                                        min: 8, message: 'Password must be in 8 words atleast',
                                    },
                                ]}
                            >
                                <Input
                                    prefixCls="input"
                                    placeholder="Your password "
                                />
                            </Form.Item>
                        </div>
                    </section>

                    <Form.Item>
                        {loading ? (
                            <div className="form-button">
                                <MotionButton
                                    tittle={"loading..."}
                                    baseColor={"#ffffff"}
                                    backgroundColor={"#000000"}
                                    hoverColor={"#000000"}
                                    texthoverColor={"#ffffff"}
                                    handleClicked={() => console.log("I am clicked")}
                                />
                            </div>
                        ) : (
                            <button className="form-button" htmlType="submit">
                                <MotionButton
                                    tittle={"Submit"}
                                    baseColor={"#ffffff"}
                                    backgroundColor={"#000000"}
                                    hoverColor={"#e2e7f1"}
                                    texthoverColor={"#090b19"}
                                    handleClicked={() => console.log("I am clicked")}
                                />
                            </button>
                        )}
                    </Form.Item>
                </Form>
            </main>
        </LoginWrapper>
    );
}

const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #f3f6fc;
  display: flex;
  align-items: center;
  justify-content: center;

  main {
    width: 100%;
    max-width: 600px;
    height: auto;
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 20px #0003;

    h1 {
      font-size: 38px;
      line-height: 1.2em;
      font-weight: 600;
      letter-spacing: -0.03em;
      padding-bottom:20px;
      text-align:center;
    }
  }

  label {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #090b19;
    padding-bottom:8px;
}

  .input {
    width:100%;
    min-height: 48px;
    margin-bottom: 0px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 18px;
    border-style: solid;
    border-width: 1px;
    border-color: #e2e7f1;
    border-radius: 2px;
    background-color: hsla(0, 0%, 100%, 0.65);
    -webkit-transition: all 400ms cubic-bezier(.25, .46, .45, .94);
    transition: all 400ms cubic-bezier(.25, .46, .45, .94);
}
.form-button {
    width: fit-content;
    background:transparent;
    border-style:none;
    margin:20px 0;
    border-radius: 28px;
    
}
`;
