import React from "react";
import styled from "styled-components";
import { AppContainer, FlexBox } from "../../Styles/GobalStyle";
import Header from "../../components/Header";
import { BgShade } from "../../utils/Images";
import { TypeAnimation } from "react-type-animation";
import { Fade } from "react-awesome-reveal";
import { Grid } from "antd";
const { useBreakpoint } = Grid;

const typeAnimationStyle = {
  fontFamily: "Inter Tight",
  fontSize: "60px",
  fontWeight: "600",
  lineHeight: "72.6px",
  letterSpacing: "0.1em",
  textAlign: "center",
  color: "#ffffff",
};

const typeAnimationText = [
  "THE MOST TALENTED TEAM",
  2000,
  "EXPERTS IN SOLVING CHALLENGES",
  2000,
  "THE BEST MINDS IN TECH",
  2000,
];
const HeaderText = ["SWAROOP", "IT", "SOLUTIONS"];
const EffectText = [
  "Impactful",
  "UI/UX",
  "-",
  "App",
  "development",
  "and",
  "high-end",
  " websites",
  " for",
  " creatives",
];
export default function PortfolioMain() {
  const screens = useBreakpoint();
  return (
    <PortfolioMainWrapper>
      <AppContainer height="fit-content">
        <Header />
      </AppContainer>
      <PortfolioInnerWrapper>
        <FlexBox>
          {HeaderText?.map((h, i) => (
            <Fade direction="up" delay={i * 200} duration={2000}>
              <header>{h}</header>
            </Fade>
          ))}
        </FlexBox>

        <WeHave>
          {Object?.keys(screens)?.length && (
            <TypeAnimation
              className="typeAnimation"
              sequence={typeAnimationText}
              style={
                screens?.xl
                  ? { ...typeAnimationStyle, fontSize: "60px" }
                  : screens?.lg
                    ? { ...typeAnimationStyle, fontSize: "50px" }
                    : screens?.md
                      ? { ...typeAnimationStyle, fontSize: "35px" }
                      : screens?.sm
                        ? { ...typeAnimationStyle, fontSize: "18px" }
                        : { ...typeAnimationStyle, fontSize: "14px" }
              }
              repeat={Infinity}
            />
          )}
          <img src={BgShade} alt="" />
          <h1> we Have </h1>
        </WeHave>
        <BottomLineWrap>
          {EffectText?.map((t, i) => (
            <Fade direction="up" delay={i * 200} duration={2000}>
              <p>{t}</p>
            </Fade>
          ))}
        </BottomLineWrap>
      </PortfolioInnerWrapper>
    </PortfolioMainWrapper>
  );
}

const BottomLineWrap = styled.div`
  display: flex;
  p {
    font-family: Inter Tight;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
    color: #111111;
    margin: 0 5px;
    @media (max-width: 1130px) {
      font-size: 22px;
    }
    @media (max-width: 735px) {
      font-size: 18px;
    }
    @media (max-width: 735px) {
      font-size: 16px;
    }
    @media (max-width: 571px) {
      font-size: 12px;
      margin: 0 2px;
    }
    @media (max-width: 390px) {
      font-size: 11px;
      margin: 0 1px;
    }
  }
`;

const WeHave = styled.div`
  width: 100%;
  position: relative;

  h1 {
    width: 100%;
    font-family: Bebas Neue;
    font-size: 455.78px;
    font-weight: 400;
    line-height: 456px;
    text-align: center;
    color: #111111;

    @media (max-width: 1250px) {
      font-size: 355.78px;
      line-height: 346px;
    }
    @media (max-width: 1000px) {
      font-size: 255.78px;
      line-height: 256px;
    }
    @media (max-width: 735px) {
      font-size: 155.78px;
      line-height: 156px;
    }
    @media (max-width: 425px) {
      font-size: 110px;
      line-height: 110px;
    }
  }

  img {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;
    z-index: 1;
    @media (max-width: 1199px) {
      height: 80px;
      top: 120px;
    }
    @media (max-width: 992px) {
      height: 60px;
      top: 90px;
    }
    @media (max-width: 735px) {
      height: 40px;
      top: 50px;
    }
    @media (max-width: 425px) {
      height: 20px;
      top: 40px;
    }
  }

  span {
    position: absolute;
    top: 165px;
    left: 0;
    right: 0;
    z-index: 2;
    font-family: Inter Tight;
    font-size: 60px;
    font-weight: 600;
    line-height: 72.6px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #ffffff;
    @media (max-width: 1199px) {
      top: 125px;
    }
    @media (max-width: 992px) {
      top: 85px;
    }
    @media (max-width: 735px) {
      top: 35px;
    }
    @media (max-width: 425px) {
      top: 15px;
    }
  }
`;

const PortfolioInnerWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding-top: 169px;

  header {
    font-family: Inter Tight;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.72px;
    text-align: left;
    color: #f16136;
    margin: 0 6px;
    @media (max-width: 425px) {
      font-size: 22px;
    }
  }
`;

const PortfolioMainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  @media(max-width:600px){
  height: auto;
  }
`;
