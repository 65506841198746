import React, { useRef } from "react";
import styled from "styled-components";
import {
  AboutUsBackground,
  CEO,
  Intro,
  Swaroop,
} from "../utils/Images";
import { AppContainer } from "../Styles/GobalStyle";
import MotionButton from "../animations/MotionButton";
import { useNavigate } from "react-router-dom";

export default function AboutUs() {
  const navigate = useNavigate();
  const cursorRef = useRef(null);

  const onMouseMove = (e) => {
    if (cursorRef.current) {
      cursorRef.current.style.left = e.clientX + "px";
      cursorRef.current.style.top = e.clientY + "px";
    }
  };

  return (
    <AboutUsWrapper background={AboutUsBackground}>
      <div className="show-light-container" onMouseMove={onMouseMove}>
        <div className="cursor-pointer" ref={cursorRef}></div>
        <AppContainer>
          <FirstSection>
            <section>
              <p>Who We Are?</p>
              <h1>We help business stand up</h1>
            </section>
            <div className="position-motionBtn">
              <MotionButton
                tittle={"Know more"}
                baseColor={"#ffffff"}
                backgroundColor={""}
                hoverColor={"#fff"}
                texthoverColor={"#000"}
                handleClicked={() => navigate('/portfolio')}
              />
            </div>
          </FirstSection>

          <SecondSection>
            <main>
              <div>
                <h3>INTROCUTION</h3>
                <p>
                  Swaroop Technologies is an certified global software services
                  company, founded in 2012. The headquarters of the company are
                  in Delhi, New Delhi, India. Since then, we have successfully
                  expanded our business globally, and you can find our other
                  offices in the United States of America, the United Arab
                  Emirates, Singapore, Australia, and the United Kingdom. <br />
                  We always understand all the problems of the clients and
                  execute them accordingly in real time with best way to solve
                  it with high quality.
                </p>
              </div>
              <img src={Intro} alt="Aboutus" />
            </main>

            <main style={{ flexDirection: "row-reverse" }}>
              <div>
                <h3>Why People Choose Us</h3>
                <p>
                  WE COMMIT TO WORLD-CLASS SERVICES THAT SET GREAT BUSINESS
                  STANDARDS
                  <br />
                  <p>
                    Our Mission - <br />
                    Invest in the latest technologies and develop ideas that
                    will help businesses reshape their future globally.
                  </p>
                  <p>
                    Our Vision - <br />
                    To be recognized for offering remarkable, world-class
                    services by connecting the latest technology and innovative
                    ideas that drive growth.
                  </p>
                  <p>
                    Our Strategy - <br />
                    We believe in delivering the highest value of services to
                    our clients, which is why we have been awarded and
                    recognized for our excellent and exceptional work.
                  </p>
                </p>
              </div>
              <img src={AboutUsBackground} alt="Aboutus" />
            </main>
          </SecondSection>

          <ThirdSection>
            <section>
              <p>Meet the team</p>
              <h1>Our Pillars Of Strength Setting The Business Standards</h1>
            </section>
            <main>
              <ReviewRatingBox>
                <div className="hoverClass"></div>
                <ContentBox>
                  <div className="swaroop-waterlogo">
                    <p>Powered by - </p>
                    <img src={Swaroop} alt="swaroop" />
                  </div>
                  <section className="photos">
                    <img src={CEO} alt="CEO" />
                    <div>
                      <h3>SANJEEV KUMAR</h3>
                      <h5>CEO & Founder</h5>
                    </div>
                  </section>
                  <section className="content">
                    <p>
                      Mr. Sanjeev Kumar is the CEO & Founder of Swaroop IT
                      solutions. He has 20+ years of experience. He manages
                      multiple roles and always available to take every step for
                      the growth of the company.
                    </p>
                  </section>
                </ContentBox>
              </ReviewRatingBox>
            </main>
          </ThirdSection>
        </AppContainer>
      </div>
    </AboutUsWrapper>
  );
}

const ContentBox = styled.div`
  position: relative;
  z-index: 8;
  height: 100%;
  display: flex;
  padding: 20px;

  @media(max-width:600px){
    flex-direction:column;
  }

  .swaroop-waterlogo {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 20px;
    align-items: center;
    img {
      height: 25px;
    }
    p {
      color: #6e7488;
      font-size: 12px;
      line-height: 1.6em;
      padding: 5px 0 0 0;
    }
  }

  .photos {
    width: 100%;
    display: flex;
    align-items: center;
    img {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      object-fit: cover;
    }

    > div {
      text-align: center;
      h3 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 700;
        letter-spacing: 1px;
      }

      h5 {
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        letter-spacing: -0.02em;
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
  }

  &:hover {
    p,
    h3,
    h5 {
      transform: scale(1.05);
      transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
      // text-shadow: 1px 1px #979797;
      color: #000;
    }

    .swaroop-waterlogo {
      display: flex;
    }
  }

  p {
    color: #6e7488;
    font-size: 17px;
    line-height: 1.6em;
    padding: 10px 20px;
    @media(max-width:600px){
      padding: 10px 20px 50px 20px;
    }
  }
`;

const ReviewRatingBox = styled.div`
  width: 60%;
  border: 1px solid #f3f6fc;
  cursor: pointer;
  position: relative;
  margin: 40px auto;
  border-radius: 24px;

  @media(max-width:998px){
    width: 80%;
  }
  @media(max-width:750px){
    width: 100%;
  }

  .hoverClass {
    display: none;
    z-index: 6;
  }

  &:hover {
    transform: scale(1.06);
    transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    box-shadow: 1px 1px 5px #f3f6fc;
    border-radius: 24px;

    .hoverClass {
      display: unset;
      position: absolute;
      inset: 0;
      z-index: 6;
      background: rgb(243, 246, 252);
      animation-name: hoverClassAnimation;
      animation-duration: 0.8s;
      border-radius: 24px;

      @keyframes hoverClassAnimation {
        0% {
          width: 0;
          height: 0;
          border-radius: 24px;
          background: rgb(243, 246, 252);
          z-index: 6;
        }
        30% {
          border-radius: 24px 24px 50% 24px;
        }
        50% {
          border-radius: 24px 24px 100% 24px;
        }
        70% {
          border-radius: 24px 24px 50% 24px;
        }
        100% {
          width: 100%;
          border-radius: 24px;
          background: rgb(243, 246, 252);
          z-index: 6;
        }
      }
    }
  }
`;

const ThirdSection = styled.div`
  width: 100%;
  section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #fff;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
    h1 {
      font-size: 48px;
      line-height: 1.2em;
      font-weight: 600;
      letter-spacing: -0.03em;
      @media (max-width: 500px) {
        font-size: 36px;
      }
    }
  }

  main {
    width: 100%;
    height: 100%;
  }
`;

const SecondSection = styled.div`
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  gap: 100px;

  main {
    width: 100%;
    display: flex;
    align-items: center;

    div {
      padding: 0 40px;
      @media (max-width: 1100px) {
        padding: 0 20px;
      }
      h3 {
        color: #ffffff;
        font-size: 44px;
        font-style: normal;
        font-weight: 500;
        padding-right: 40px;
      }
      p {
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        margin: 10px 0 0 0;
        padding-right: 40px;
      }
    }
    img {
      width: 448px;
      height: 448px;
      border-radius: 24px;
      object-fit: cover;
      @media (max-width: 1100px) {
        width: 348px;
        height: 348px;
      }

      @media (max-width: 850px) {
          width: 448px;
          height: 448px;
          margin-top: 40px;
      }

      @media (max-width: 512px) {
            width: 100%;
            height: 100%;
            margin-top: 40px;
      }

    }
  }


  @media (max-width: 850px) {
    main {
      flex-direction: column !important;
      div {
        p {
          padding: 0;
        }
      }
    }
  }

  @media(max-width:443px){
    main {
      div {
        padding: 0;
        h3{
          font-size: 34px;
        }
      }
    }
  }
`;

const FirstSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
    section {
      margin-bottom: 20px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #fff;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
    h1 {
      font-size: 48px;
      line-height: 1.2em;
      font-weight: 600;
      letter-spacing: -0.03em;
      @media (max-width: 500px) {
        font-size: 36px;
      }
    }
  }
`;

const AboutUsWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;

  .show-light-container {
    padding: 80px 0;
    width: 100%;
    // height: 120vh;
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    cursor: none;

    &:hover {
      .cursor-pointer {
        display: block;
      }
    }
  }

  .show-light-container-text {
    position: relative;
    color: white;
    transition: 0.2s;
  }

  .cursor-pointer {
    display: none;
    position: fixed;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: white;
    transition: 0.1s;
    transform: translate(-50%, -50%);
    pointer-events: none;
    mix-blend-mode: difference;
  }

  .show-light-container-text:hover ~ .cursor-pointer {
    transform: scale(3);
  }
`;
