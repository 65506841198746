import React from "react";
import styled from "styled-components";
import { LandingBackground } from "../utils/Images";
import { AppContainer, BlackLayer } from "../Styles/GobalStyle";
import SplashWelcome from "../animations/SplashWelcome";
import CircleMoving from "../animations/CircleMoving";
import MotionButton from "../animations/MotionButton";
import Header from "../components/Header";

export default function MainLanding({ servicesRef, projectRef, contactRef }) {
  const scheduleHandleClicked = () => {
    const a = document.createElement("a");
    a.href = "tel:+917827559507";
    a.click();
  };

  const handleCircleMoving = () => {
    servicesRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <MainLandingWrapper back={LandingBackground}>
      <SplashWelcome />
      <BlackLayer />
      <AppContainer>
        <Header
          servicesRef={servicesRef}
          projectRef={projectRef}
          contactRef={contactRef}
        />
        <MainLandingInnerWrapper>
          <header className="first-header">
            Welcome to <span>Swaroop</span>
          </header>
          <header className="second-header">
            Digital Dreams,
            <br />
            Real Solutions.
          </header>

          <div className="position-motionBtn">
            <MotionButton
              tittle={"Schedule a call"}
              baseColor={"#ffffff"}
              // backgroundColor={""}
              hoverColor={"#090b19"}
              texthoverColor={"#ffffff"}
              handleClicked={scheduleHandleClicked}
            />
          </div>
          <CircleMoving handleClicked={handleCircleMoving} />
        </MainLandingInnerWrapper>
      </AppContainer>
      <Features>
        <div className="first-child">
          <p>Contact us</p>
        </div>
        <div className="second-child">
          <div>
            <h4>Email</h4>
            <p>connect.swaroopitsolutions@gmail.com</p>
          </div>
          <div>
            <h4>Address</h4>
            <p>3087, Second Floor, Sangtrashan, Paharganj,</p>
            <p>New Delhi, 110055, India</p>
          </div>

          {/* <h2>MetLife Corporate Space</h2> */}
        </div>
      </Features>
    </MainLandingWrapper>
  );
}

const Features = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 36.5%;
  height: 158px;
  display: flex;
  z-index: 8;

  @media (max-width: 650px) {
    display: none;
  }

  .first-child {
    width: 17.5%;
    background: #f3f6fc;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      width: fit-content;
      color: #6e7488;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      // line-height: 125%;
      transform: rotate(270deg);
    }
  }
  .second-child {
    width: 82.5%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 0 20px;
    gap: 20px;
    &:hover {
      background: #ffffff66;
      cursor: pointer;
    }

    @media (max-width: 992px) {
      width: 400px;
    }

    h4 {
      width: fit-content;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      letter-spacing: 4px;
      background: transparent;
    }

    h2 {
      width: fit-content;
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 4px;
      padding: 20px 0;
    }

    p {
      width: fit-content;
      color: rgb(0 0 0 / 80%);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      letter-spacing: 1px;
      background: transparent;
    }
  }
`;

const MainLandingInnerWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 89px);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;

  @media (max-width: 450px) {
    align-items: center;
  }

  .first-header {
    padding: 0 0 20px 0;
  }

  header {
    color: #fff;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 40px */
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    opacity: 1;
    transform-style: preserve-3d;

    span {
      color: #f16136;
      font-weight: 700;
    }

    @media (max-width: 600px) {
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
    }
    @media (max-width: 450px) {
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      text-align: center;
    }
  }

  .position-motionBtn {
    position: absolute;
    bottom: 80px;
  }
`;

const MainLandingWrapper = styled.div`
  width: 100%;
  height: 120vh;
  position: relative;
  background: ${({ back }) => `url(${back})`};
  background-size: cover;
`;
