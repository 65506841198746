import { AiChatBot, DatingApp, HealthApp1, RealEstateApp, ResturentApp, ShotApp } from "./Images";

export const FitnessApp = {
    image: HealthApp1,
    techUsed: "React JS, Node JS, MySQL (Sequelize)",
    desHead: "Mindful Fitness App",
    desCon:
        "An interactive fitness app based on Awareness Integration Theory (AIT). The model addresses emotional wounds, reduces anxiety and depression, covering aspects of life like Self, Friends, Work, Finance, and Romantic Relationships.",
};

export const ResturentAndRealEsateApp = [
    {
        image: ResturentApp,
        techUsed: "React JS, Node JS, MySQL (Sequelize)",
        desHead: "DineInSync: Restaurant Management Platform",
        desCon:
            "DineInSync facilitates restaurant owners in adding and managing their establishments. Admin provides secure login credentials, allowing owners to customize their restaurants with specific roles, QR codes, and items. Customers use the mobile app to scan QR codes, placing orders and making payments with real-time notifications through the restaurant's web interface.",
    },
    {
        image: RealEstateApp,
        techUsed: "React",
        desHead: "Streamlined Real Estate Web App",
        desCon:
            "A dedicated web application for the real estate industry, offers users access to a comprehensive listing of nearby properties for sale.The platform simplifies the buying process by enabling direct communication with property owners.",
    },
];

export const AiChatApp = {
    video: AiChatBot,
    techUsed: "spaCy, scikit-learn, Rasa",
    desHead: "TechGPT",
    desCon:
        "TechGPT is a GPT designed to answer tech-related questions, including coding, the latest news, and how technology works.",
};

export const DatingAndShotApp = [
    {
        image: ShotApp,
        techUsed: "React Native, Firebase, Mongo DB",
        desHead: "LocalVibes",
        desCon:
            "LocalVibes is a cross-platform app for Android and iOS, providing a TikTok-like experience. Users can create and share short videos, uniquely featuring content from nearby locations. Built with React Native, the app's admin panel, responsible for monitoring user activity, is developed using React JS.",
    },
    {
        image: DatingApp,
        techUsed: "Kotlin, Swift, Mongo DB, Pusher",
        desHead: "HeartConnect",
        desCon:
            "HeartConnect is a dating application catering to both Android and iOS users. It facilitates matches, allowing users to chat and connect for potential dating opportunities. The app comes in two versions – one tailored specifically for Africans and another catering to users worldwide.",
    },
];