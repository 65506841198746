import React, { useEffect } from "react";
import PortfolioMain from "../module/Portfolio/PortfolioMain";
import InfoSection from "../module/Portfolio/InfoSection";
import WeHelpYou from "../module/Portfolio/WeHelpYou";
import ProjectContainer from "../module/Portfolio/ProjectContainer";
import Footer from "../components/Footer";
import GetInTouch from "../module/Portfolio/GetInTouch";
import { useLocation } from "react-router-dom";

export default function PortfolioLayout() {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0, { behavior: "smooth" });
    }, [location]);
    return (
        <div>
            <PortfolioMain />
            <InfoSection />
            <WeHelpYou />
            <ProjectContainer />
            <GetInTouch />
            <Footer />
        </div>
    );
}
