import React from "react";
import { AttentionSeeker } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AvatarEmoji2 } from "../../utils/Images";

export default function GetInTouch() {
  const navigate = useNavigate();
  const Text =
    "Get in touch - Get in touch - Get in touch - Get in touch - Get in touch - Get in touch - Get in touch - Get in touch";
  const handleClicked = () => {
    navigate("/");
    window.scrollTo(0, 0, { behavior: "smooth" });
    // contactRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <GetInTouchWrapper>
      <section className="get-in-touch">
        <Movement>
          <div className="right-to-left">
            <p>{Text}</p>
          </div>

          <div className="left-to-right">
            <p>{Text}</p>
          </div>
        </Movement>
        <div className="click-button" onClick={handleClicked}>
          Click
        </div>
      </section>
      <section className="have-an-idea">
        <h1>HAVE AN IDEA? LET’S</h1>
        <h1 className="">
          <AttentionSeeker effect="rubberBand">
            <img src={AvatarEmoji2} alt="" width={130} />
          </AttentionSeeker>
          BUILD AND
        </h1>
        <h1>SCALE IT Together</h1>
      </section>
    </GetInTouchWrapper>
  );
}

const GetInTouchWrapper = styled.div`
  width: 100%;
  height: 100%;

  .get-in-touch {
    position: relative;
    padding: 120px 0;
    border-bottom: 1px solid #35353566;

    @media (max-width: 600px) {
      padding: 60px 0;
    }

    .click-button {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 124px;
      height: 124px;
      background: #111111;
      border: 2px solid #000000;
      border-radius: 100%;
      font-family: Inter Tight;
      font-size: 36px;
      font-weight: 200;
      line-height: 54px;
      text-align: left;
      color: #ffffff;
      cursor: pointer;
      top: 40%;
      left: 50%;

      @media (max-width: 600px) {
        width: 92px;
        height: 92px;
        font-size: 26px;
      }
    }
  }

  .have-an-idea {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: Bebas Neue;
      font-size: 120px;
      font-weight: 400;
      line-height: 120px;
      text-align: center;
      color: #111111;

      @media (max-width: 600px) {
        font-size: 62px;
         line-height: 62px;
      }

      &:nth-child(2) {
        display: flex;
        align-items: center;
        gap: 20px;
      }
      &:nth-child(3) {
        color: #f16136;
      }
    }
  }
`;

const Movement = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
  overflow: hidden;

  p {
    font-family: Bebas Neue;
    font-size: 140px;
    font-weight: 400;
    line-height: 140px;
    text-align: left;
    color: #fff;
    white-space: nowrap;

    @media (max-width: 600px) {
      font-size: 80px;
      line-height: 80px;
    }
    @media (max-width: 435px) {
      font-size: 42px;
      line-height: 42px;
    }
  }

  .right-to-left {
    p {
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

      animation: rtl-animation 12s linear infinite;
      @keyframes rtl-animation {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-2500px);
        }
      }

      @media (max-width: 600px) {
        animation: rtl-animation1 8s linear infinite;
        @keyframes rtl-animation1 {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(-1000px);
          }
        }
      }
    }
  }

  .left-to-right {
    p {
      color: #000;

      animation: ltr-animation 12s linear infinite;
      @keyframes ltr-animation {
        from {
          transform: translateX(-2500px);
        }
        to {
          transform: translateX(0);
        }
      }

      @media (max-width: 600px) {
        animation: ltr-animation1 8s linear infinite;
        @keyframes ltr-animation1 {
          from {
            transform: translateX(-1000px);
          }
          to {
            transform: translateX(0);
          }
        }
      }
    }
  }
`;
