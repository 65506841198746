import { postApi, getApi, putApi, deleteApi } from "./ApiMethod";


const CREATE_CONTACT = "contact/create";

export function createContact(payload) {
  return postApi(CREATE_CONTACT, payload);
}

// export function getRecomendedItemForNotInCart(id) {
//   return getApi(GET_RECOMENDED_NOT_IN_CART_ITEMS + "?qr_code_id=" + id);
// }
