import React from "react";
import styled from "styled-components";
import { AppContainer } from "../Styles/GobalStyle";
import MotionButton from "../animations/MotionButton";
import { useNavigate } from "react-router-dom";

export default function FindInspiration({ projectRef }) {
  const navigate = useNavigate();
  const rightSectionData = [
    {
      date: "Technology Used: Node.js, Express, Postgres, Stripe",
      title: "Media & Marketplace Fusion",
      content: `This App combines social media dynamics with an integrated marketplace. Users can share media, explore content by tags, and participate in a personalized feed. The platform also supports item sales through fixed prices or auctions.`,
    },
    {
      date: "Technology Used: Node.js, Express, MongoDB, Firebase.",
      title: "Mobile Enterprise Management",
      content: `This App empowers seamless business management from your mobile device. Effortlessly upload video, audio, or PDF content, inform your network, and leverage the app for teaching, training, or selling through in-app purchases.`,
    },
    {
      date: "Technology Used: Node.js, Express, MySQL (Sequelize)",
      title: "Mindful Fitness App",
      content: `An interactive fitness app based on Awareness Integration Theory (AIT). The model addresses emotional wounds, reduces anxiety and depression, covering aspects of life like Self, Friends, Work, Finance, and Romantic Relationships.`,
    },
    {
      date: "Technology Used: Node.js, Express, MySQL (Sequelize)",
      title: "Travel Planner",
      content: `This project streamlines trip organization, offering users trip packages, day-by-day planning, hotel booking, and cab reservation.`,
    },
    {
      date: "Technology Used: React",
      title: "DineInSync: Restaurant Management Platform",
      content: `DineInSync facilitates restaurant owners in adding and managing their establishments. Admin provides secure login credentials, allowing owners to customize their restaurants with specific roles, QR codes, and items. Customers use the mobile app to scan QR codes, placing orders and making payments with real-time notifications through the restaurant's web interface.`,
    },
    {
      date: "Technology Used: React",
      title: "Streamlined Real Estate Web App",
      content: `A dedicated web application for the real estate industry, offers users access to a comprehensive listing of nearby properties for sale. The platform simplifies the buying process by enabling direct communication with property owners.`,
    },
    {
      date: " Technology Used: React",
      title: "Proximity-Centric Video Sharing App",
      content: `LocalVibes is a cross-platform app for Android and iOS, providing a TikTok-like experience. Users can create and share short videos, uniquely featuring content from nearby locations. Built with React Native, the app's admin panel, responsible for monitoring user activity, is developed using React JS.`,
    },
    {
      date: " Technology Used: React",
      title: "Global Dating App",
      content: ` HeartConnect is a dating application catering to both Android and iOS users. It facilitates matches, allowing users to chat and connect for potential dating opportunities. The app comes in two versions – one tailored specifically for Africans and another catering to users worldwide.`,
    },
  ];
  return (
    <FindInspirationWrapper ref={projectRef}>
      <AppContainer>
        <FindInspirationInnerWrapper>
          <LeftSection>
            <DummyLines>
              <span></span>
            </DummyLines>
            <h1>Find inspiration for your next project</h1>
            <p>
              Proin euismod, urna vitae auctor venenatis, mi metus consectetur
              est, non rutrum sapien eros non mauris. Praesent ex felis,
              bibendum vel a, aliquet iaculis nunc. Pellentesque neque dolor,
              pretium ac ligula in.
            </p>

            <div className="position-motionBtn">
              <MotionButton
                tittle={"View inspiration"}
                baseColor={"#ffffff"}
                backgroundColor={"#000000"}
                hoverColor={"#e2e7f1"}
                texthoverColor={"#090b19"}
                handleClicked={() => navigate('/portfolio')}
              />
            </div>
          </LeftSection>

          <RightSection>
            {rightSectionData?.map((ele) => (
              <div className="findinspiration-box">
                <span>{ele.date}</span>
                <div>
                  <h3>{ele.title}</h3>
                  <p>{ele.content}</p>
                </div>
              </div>
            ))}
          </RightSection>
        </FindInspirationInnerWrapper>
      </AppContainer>
    </FindInspirationWrapper>
  );
}

const LeftSectionMobile = styled.div`
width: 100%;

> h1 {
  font-size: 48px;
  line-height: 1.2em;
  font-weight: 600;
  letter-spacing: -0.03em;
  margin: 0 0 20px 0;
  padding-right: 80px;
  @media (max-width: 780px) {
    padding-right: 40px;
  }
  @media (max-width: 685px) {
    font-size: 36px;
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: -0.03em;
  }
}
> p {
  color: #6e7488;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0 0 40px 0;
  padding-right: 80px;
  @media (max-width: 780px) {
    padding-right: 40px;
  }
}
`;

const RightSection = styled.div`
  top: 0;
  width: 50%;
  margin-left: 50%;
  border-left: 1px solid rgba(136, 158, 168, 0.2);
  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0;
    border-style: none;
  }

  .findinspiration-box {
    position: relative;
    height: 100%;
    padding: 60px 0 60px 80px;
    @media (max-width: 780px) {
      padding: 60px 0 60px 40px;
    }
    @media (max-width: 600px) {
      padding: 30px 0;
    }

    span {
      color: #626d86;
      font-size: 11px;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    > div {
      display: flex;
      align-items: start;
      justify-content: space-between;
      padding: 20px 0;
      gap: 20px;
      @media (max-width: 992px) {
        flex-direction: column;
      }
    }
    h3 {
      font-size: 24px;
      line-height: 1.3em;
      font-weight: 500;
      letter-spacing: -0.03em;
    }

    p {
      color: #6e7488;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: calc(100% - 80px);
      height: 1px;
      background: #d7dfeb;
      @media (max-width: 600px) {
       display:none;
      };
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: calc(100% - 80px);
      height: 1px;
      background: #d7dfeb;
      @media (max-width: 600px) {
        width: 100%;
        background: #adb3bb;
      };
    }
  }
`;

const LeftSection = styled.div`
  width: 50%;
  position: sticky;
  top: 212px;
  border-right: 1px solid rgba(136, 158, 168, 0.2);
  @media (max-width: 600px) {
    width: 100%;
    position: relative;
    top: 0;
    border-right: none;
    margin-bottom:40px;
  }

  > h1 {
    font-size: 48px;
    line-height: 1.2em;
    font-weight: 600;
    letter-spacing: -0.03em;
    margin: 0 0 20px 0;
    padding-right: 80px;
    @media (max-width: 780px) {
      padding-right: 40px;
    }
    @media (max-width: 685px) {
      font-size: 36px;
      line-height: 1.2em;
      font-weight: 600;
      letter-spacing: -0.03em;
    }
  }
  > p {
    color: #6e7488;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0 0 40px 0;
    padding-right: 80px;
    @media (max-width: 780px) {
      padding-right: 40px;
    }
  }
`;

const DummyLines = styled.div`
  display: flex;
  width: 60px;
  height: 2px;
  background-color: #d7dfeb;
  margin-bottom: 20px;

  span {
    width: 40%;
    height: 100%;
    background-color: #a3acc2;
  }
`;

const FindInspirationInnerWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  position: relative;
`;

const FindInspirationWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 120px;
`;
