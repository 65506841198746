import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import AdminHeader from "../components/AdminHeader";
import AdminSidenav from "../components/AdminSidenav";

export default function PrivateLayout() {
  const [isMinimized, setIsMinimized] = useState(false);

  return (
    <PrivateLayoutWrapper isMinimized={isMinimized} >
      <section className="sidenav-wrapper">
        <AdminSidenav isMinimized={isMinimized} setIsMinimized={setIsMinimized} />
      </section>
      <main>
        <header>
          <AdminHeader />
        </header>
        <section className="outlet-wrapper">
          <Outlet />
        </section>
      </main>
    </PrivateLayoutWrapper>
  );
}

const PrivateLayoutWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #f5efeb80;


  .sidenav-wrapper {
    width: ${({ isMinimized }) => isMinimized ? "90px" : "280px"};
    margin:20px;
    transition: all 0.8s;
  }

  main {
    width: ${({ isMinimized }) => isMinimized ? "calc(100% - 150px)" : "calc(100% - 340px)"};
    display:flex;
    flex-direction:column;
    gap:20px;
    margin:20px 0;
    transition: all 0.3s;
  }
  header {
    width: 100%;
    height: 78px;
  }

  .outlet-wrapper {
    width: 100%;
    height: calc(100vh - 138px);
    background: #fff;
    border: 0.5px solid #f5efeb66;
    border-radius: 28px;
    box-shadow: 0px 64px 64px -32px #6625008f;
    overflow-y:scroll;
  }
`;
