import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { AppContainer } from "../../Styles/GobalStyle";
import { Fade } from "react-awesome-reveal";

export default function InfoSection() {
  const Text =
    "Website - Mobile App - UI/UX - Hybrid Mobile Development - Custom Web Development - Ecommerce Development";
  const [scrollPosition, setScrollPosition] = useState(100);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 1200 && position < 1500) {
      setScrollPosition(90);
    } else if (position > 1500 && position < 1800) {
      setScrollPosition(60);
    } else if (position > 1800 && position < 2000) {
      setScrollPosition(30);
    } else if (position > 2000 && position < 2500) {
      setScrollPosition(0);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <Movement>
        <div className="right-to-left">
          <p>{Text}</p>
        </div>

        <div className="left-to-right">
          <p>{Text}</p>
        </div>
      </Movement>

      <SubInfoWrap>
        <AppContainer height="0">
          <div className="section1">
            <h1>
              <span>You need a Digital </span>Solutions
            </h1>
            <Fade direction="down" duration={2000}>
              <h1 className="opacity01">
                You need a high-performing Digital Solutions
              </h1>
            </Fade>
          </div>

          <div className="section2">
            <p>
              This word will make the difference between a simple visit on your
              website and a sale.
            </p>
            <p>
              Mobile and react expert, I help you solve your online visibility
              challenges and generate more sales by designing custom websites,
              art direction dedicated to your brand and bespoke digital
              strategies.
            </p>
          </div>
        </AppContainer>
      </SubInfoWrap>
      <CurveBlack scrollPosition={scrollPosition} />
    </div>
  );
}

const CurveBlack = styled.div`
  position: relative;

  width: 100%;
  height: 50vh;
  background: #111111;
  margin-top: 704px;
  border-top-left-radius: ${({ scrollPosition }) => `${scrollPosition}%`};
  border-top-right-radius: ${({ scrollPosition }) => `${scrollPosition}%`};
  z-index: 99;
  transition: all 0.51s;

  @media (max-width: 992px) {
    height: 30vh;
  }
  @media (max-width: 600px) {
    height: 20vh;
  }
`;

const SubInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  width: 100%;
  position: sticky;
  top: 89px;
  background: red;

  h1 {
    font-family: Bebas Neue;
    font-size: 60px;
    font-weight: 400;
    line-height: 60px;
    text-align: left;
    color: #111111;

    @media (max-width: 600px) {
      font-size: 42px;
      line-height: 42px;
    }
  }
  .section1 {
    display: flex;
    flex-direction: column;
    gap: 24px;
    h1 {
      &:nth-child(1) {
        span {
          text-decoration: line-through;
          text-decoration-color: #f16136;
        }
      }
    }

    .opacity01 {
      opacity: 0.1;
    }
  }

  .section2 {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (max-width: 600px) {
      width: 100%;
    }

    p {
      font-family: Inter Tight;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #111111;
    }
  }
`;

const Movement = styled.div`
  width: 100%;
  padding: 96px 0;
  overflow: hidden;

  @media (max-width: 1025px) {
    padding: 76px 0;
  }
  @media (max-width: 600px) {
    padding: 46px 0;
  }
  p {
    font-family: Bebas Neue;
    font-size: 140px;
    font-weight: 400;
    line-height: 140px;
    text-align: left;
    color: #fff;
    white-space: nowrap;

    @media (max-width: 600px) {
      font-size: 80px;
      line-height: 80px;
    }
    @media (max-width: 435px) {
      font-size: 42px;
      line-height: 42px;
    }
  }

  .right-to-left {
    padding: 24px 0;
    @media (max-width: 600px) {
      padding: 10px 0;
    }
    p {
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
      animation: rtl-animation 12s linear infinite;
      @keyframes rtl-animation {
        from {
          transform: translateX(0);
        }
        to {
          transform: translateX(-2500px);
        }
      }

      @media (max-width: 600px) {
        animation: rtl-animation1 8s linear infinite;
        @keyframes rtl-animation1 {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(-1000px);
          }
        }
      }
    }
  }

  .left-to-right {
    padding: 24px 0;
    @media (max-width: 600px) {
      padding: 10px 0;
    }
    p {
      color: #000;
      animation: ltr-animation 12s linear infinite;
      @keyframes ltr-animation {
        from {
          transform: translateX(-2500px);
        }
        to {
          transform: translateX(0);
        }
      }

      @media (max-width: 600px) {
        animation: ltr-animation1 8s linear infinite;
        @keyframes ltr-animation1 {
          from {
            transform: translateX(-1000px);
          }
          to {
            transform: translateX(0);
          }
        }
      }
    }
  }
`;
