import React from "react";
import { Space, Table, Tag } from "antd";
import styled from "styled-components";

const columns = [
    {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        render: (text) => <a>{text}</a>,
    },
    {
        title: "EMAIL ADDRESS",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "MESSAGE",
        dataIndex: "message",
        key: "message",
    },
    {
        title: "ACTION",
        key: "action",
        render: (_, record) => (
            <Space size="middle">
                <a>Invite {record.name}</a>
                <a>Delete</a>
            </Space>
        ),
    },
];

const data = [
    {
        key: "1",
        name: "Jayant kumar",
        email: "Jay@gmail.com",
        message: "New York No. 1 Lake Park",
    },
];

const tab = ["all", "read", "unread"];
export default function GetTouchIndex() {
    return (
        <GetTouchIndexWrapper>
            <TabWrapper>{tab && tab?.map((t) => <section>{t}</section>)}</TabWrapper>
            <Table columns={columns} dataSource={data} />
        </GetTouchIndexWrapper>
    );
}

const TabWrapper = styled.div`
  width: 100%;
  height: 100%;
  display:flex;
  gap:16px;

  section {
    display:flex;
    align-items:center;
    justify-content:center;
    height: 52px;
    padding:0 20px;
    border: 0.5px solid #f5efeb66;
    border-radius: 8px;
    box-shadow: 0px 64px 64px -32px #6625008f;
    background: #f8f4f2;
  }
`;

const GetTouchIndexWrapper = styled.div`
  padding: 16px;
`;
