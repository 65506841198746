import React, { useRef, useState } from "react";
import styled from "styled-components";
import { AiChatBotthumbnail } from "../utils/Images";

export default function CustomVideoPlayer({ src }) {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    return (
        <CustomVideoPlayerWrapper isPlaying={isPlaying}>
            <video
                ref={videoRef}
                width="100%"
                playsInline
                loop
                muted
                controls={false}
                poster={AiChatBotthumbnail}
            >
                <source src={src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {!isPlaying && (
                <div className="play-button" onClick={handlePlay}>
                    &#9658;
                </div>
            )}
        </CustomVideoPlayerWrapper>
    );
}

const CustomVideoPlayerWrapper = styled.div`
  position: relative;
  display: inline-block;

  video {
    display: block;
    width: 100%;
    height: auto;
    filter: ${({ isPlaying }) => (isPlaying ? "grayscale(0)" : "grayscale(1)")};
    cursor: crosshair;
    border-radius: 16px;
    opacity: 1;

    @media (max-width: 600px) {
      filter: grayscale(0);
      opacity: 1;
    }

    &:hover {
      filter: grayscale(0);
      opacity: 1;
      transform: scale(0.95);
      transition: transform 0.4s;
    }
  }

  .play-button {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    color: white;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 0 0 0 6px;
    text-align: center;
    user-select: none;

    @media (max-width: 600px) {
      width: 60px;
      height: 60px;
      font-size: 28px;
    }
  }
`;
