import React, { useState } from 'react';
import { Form, Input, notification } from 'antd';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import MotionButton from '../animations/MotionButton';
import { createContact } from '../services/Collection';

export default function ContactForm() {
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const openNotification = (placement, message) => {
        api.success({
            message: `Swaroop IT ${placement}`,
            description: message, placement,
            duration: 6,
        });
    };

    const onFinish = async (values) => {
        setLoading(true);
        let req = {
            "name": values?.firstname + " " + values?.lastname,
            "email": values?.email,
            "phoneNumber": "9811956722",
            "description": values?.message
        }
        let res = await createContact(req);
        if (res?.status === 200) {
            openNotification('top', "Request submitted successfully! Swaroop will contact you back soon")
            setLoading(false)
        } else {

        }
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <ContactFormWrapper>
            {contextHolder}
            <Form
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <section>
                    <label>NAME</label>
                    <div className='inner-wrapper' >

                        <Form.Item
                            label=""
                            name="firstname"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your first name!',
                                },
                            ]}
                        >
                            <Input prefixCls='input' placeholder='Your first name' />
                        </Form.Item>

                        <Form.Item
                            label=""
                            name="lastname"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your last name!',
                                },
                            ]}
                        >
                            <Input prefixCls='input' placeholder='Your last name' />
                        </Form.Item>
                    </div>
                </section>

                <section>
                    <label>Email Address</label>
                    <div className='inner-wrapper' >

                        <Form.Item
                            label=""
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                                {
                                    type: 'email', message: 'The input is not valid E-mail!',
                                },
                            ]}
                        >
                            <Input prefixCls='input' placeholder='Your email' />
                        </Form.Item>


                    </div>
                </section>

                <section>
                    <label>message</label>
                    <div className='inner-wrapper' >

                        <Form.Item
                            label=""
                            name="message"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your message!',
                                },
                                {
                                    min: 20, message: 'Message should in 20 words atleast',
                                },
                            ]}
                        >
                            <TextArea prefixCls='input' placeholder='Your project description ' />
                        </Form.Item>


                    </div>
                </section>



                <Form.Item>
                    {loading ?
                        <div className="form-button" >

                            <MotionButton
                                tittle={"loading..."}
                                baseColor={"#ffffff"}
                                backgroundColor={"#000000"}
                                hoverColor={"#000000"}
                                texthoverColor={"#ffffff"}
                                handleClicked={() => console.log('I am clicked')}
                            />
                        </div>
                        :
                        <button className="form-button" htmlType="submit">
                            <MotionButton
                                tittle={"Submit"}
                                baseColor={"#ffffff"}
                                backgroundColor={"#000000"}
                                hoverColor={"#e2e7f1"}
                                texthoverColor={"#090b19"}
                                handleClicked={() => console.log('I am clicked')}
                            />
                        </button>
                    }

                </Form.Item>
            </Form>



        </ContactFormWrapper>
    )
}

const ContactFormWrapper = styled.div`
width:100%;


form {
    max-width:800px !important;
}
section {
    width:100%;
    display:flex;
    flex-direction:column;
    gap:12px;

    .inner-wrapper {
        width:100%;
        display:flex;
        gap:12px;
        @media(max-width:460px){
            flex-direction:column;
        }

        .ant-form-item {
            width:100%;
        }
    }

    label {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 4px;
        text-transform: uppercase;
        color: #090b19;
    }
}
.input {
    width:100%;
    min-height: 48px;
    margin-bottom: 0px;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 18px;
    border-style: solid;
    border-width: 1px;
    border-color: #e2e7f1;
    border-radius: 2px;
    background-color: hsla(0, 0%, 100%, 0.65);
    -webkit-transition: all 400ms cubic-bezier(.25, .46, .45, .94);
    transition: all 400ms cubic-bezier(.25, .46, .45, .94);
}

.form-button {
    width: fit-content;
    background:transparent;
    border-style:none;
    margin:20px 0;
    border-radius: 28px;
}

`;