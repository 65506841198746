import Credentials from "../module/credentials/Credentials";
import Dashboard from "../module/dashboard/Dashboard";
import GetTouchIndex from "../module/getInTouch/GetTouchIndex";

export const privatePaths = [
    {
        path: "dashboard",
        component: <Dashboard />
    },
    {
        path: "requests",
        component: <GetTouchIndex />
    },
    {
        path: "credentials",
        component: <Credentials />
    },

]