import React from "react";
import styled from "styled-components";
import { RightArrow } from "../utils/Icons";

const CircleMoving = ({ handleClicked }) => {
  const onMouseMove = (e) => {
    let x = e.nativeEvent.offsetX;
    let y = e.nativeEvent.offsetY;
    let textWidth = e.currentTarget.clientWidth;
    let textHeight = e.currentTarget.clientHeight;
    let transX = x - textWidth / 2;
    let transY = y - textHeight / 2;
    e.currentTarget.style.transform = `translate(${transX}px, ${transY}px) scale(1.1)`;
  };

  const onMouseLeave = (e) => {
    e.currentTarget.style.transform = "translate(0, 0) scale(1)";
  };
  return (
    <CircleMovingHover onClick={handleClicked} >
      <div className="circle-container1">
        <div
          className="circle-container2"
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
        >
          <p className="arrow-icon" >
            <RightArrow />
          </p>
        </div>
      </div>
    </CircleMovingHover>
  );
};

export default CircleMoving;

const CircleMovingHover = styled.div`
  position: absolute;
  width: fit-content;
  transition: transform 0.6s;
  right:0;
  bottom:0;
  margin: 0 50px 0 0;

  @media(max-width:600px){
    display:none;
  }

  .circle-container2 {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px rgba(255, 255, 255, 0.75) solid;
    border-radius: 100%;
    cursor: pointer;
    transition: transform 0.6s;
    color: white;
  }
  .circle-container2:hover {
    background-color: rgba(255, 255, 255, 0.75);
    color: black;
    background: rgba(255, 255, 255, 0.75);
  }

  .arrow-icon {
    transform: rotate(90deg);
  }
`;
