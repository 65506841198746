import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { AppContainer } from "../../Styles/GobalStyle";
import { JackInTheBox } from "react-awesome-reveal";
import { AvatarEmoji1 } from "../../utils/Images";
import {
  FirebaseIcon,
  NestJSIcon,
  NextJSIcon,
  NodeJSIcon,
  ReactFirebaseIcon,
  ReactJSIcon,
} from "../../utils/Icons";
import ParticleStar from "../../components/Particle";

const tech = [
  {
    name: "ReactFirebase",
    icon: <ReactFirebaseIcon />,
    background: "#1F1F1F",
  },
  {
    name: "NodeJS",
    icon: <NodeJSIcon />,
    background: "#1F1F1F",
  },
  {
    name: "ReactJS",
    icon: <ReactJSIcon />,
    background: "#FFFFFF",
  },
  {
    name: "Firebase",
    icon: <FirebaseIcon />,
    background: "#1F1F1F",
  },
  {
    name: "NextJS",
    icon: <NextJSIcon />,
    background: "#FFFFFF",
  },
  {
    name: "NestJS",
    icon: <NestJSIcon />,
    background: "#1F1F1F",
  },
  {
    name: "ReactFirebase",
    icon: <ReactFirebaseIcon />,
    background: "#1F1F1F",
  },
  {
    name: "NodeJS",
    icon: <NodeJSIcon />,
    background: "#1F1F1F",
  },
  {
    name: "ReactJS",
    icon: <ReactJSIcon />,
    background: "#FFFFFF",
  },
  {
    name: "Firebase",
    icon: <FirebaseIcon />,
    background: "#1F1F1F",
  },
  {
    name: "NextJS",
    icon: <NextJSIcon />,
    background: "#FFFFFF",
  },
  {
    name: "NestJS",
    icon: <NestJSIcon />,
    background: "#1F1F1F",
  },
];

export default function WeHelpYou() {
  return (
    <WeHelpYouWrapper>
      <ParticleStar />
      <AppContainer style={{ width: "76%" }}>
        <JackInTheBox>
          <h1>We’ll</h1>
          <h1 className="help-you">
            <JackInTheBox>
              <img src={AvatarEmoji1} alt="" />
            </JackInTheBox>
            help you
          </h1>
          <h1>Stand out</h1>
        </JackInTheBox>
      </AppContainer>

      <TechnologyWrapper>
        {tech?.map((t) => (
          <Technology icon={t?.icon} background={t?.background} />
        ))}
      </TechnologyWrapper>

      <AppContainer style={{ width: "76%" }}>
        <JackInTheBox>
          <h1>With our</h1>
          <h1 className="services">Services</h1>
        </JackInTheBox>
      </AppContainer>
    </WeHelpYouWrapper>
  );
}

const Technology = ({ icon, background }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(parseInt(position / 10) * 4);
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <TechnologyCircle scrollPosition={scrollPosition} background={background}>
      {icon}
    </TechnologyCircle>
  );
};

const TechnologyCircle = styled.div`
  width: 256px;
  height: 256px;
  background: ${({ background }) => background};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  // scroll-snap-align: center;
  z-index: 999;
  transform: ${({ scrollPosition }) =>
    scrollPosition ? `translateX(-${scrollPosition}px)` : `translateX(0)`};
  transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  svg {
    height: 120px;
  }

    @media (max-width: 992px) {
    width: 200px;
    height: 200px;
    svg {
     height: 100px;
    }
  }
  @media (max-width: 600px) {
    width: 160px;
    height: 160px;
    svg {
     height: 80px;
    }
  }
    @media (max-width: 495px) {
    width: 120px;
    height: 120px;
    svg {
     height: 60px;
    }
  }

  &:hover {
    background: #fff;
    cursor: pointer;
  }
`;

const TechnologyWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  display: flex;
  gap: 30px;
  overflow-x: scroll;
`;

const WeHelpYouWrapper = styled.div`
  position: relative;
  width: 100%;
  background: #111111;
  z-index: 99;

  h1 {
    font-family: Bebas Neue;
    font-size: 260px;
    font-weight: 700;
    line-height: 220px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #fff;

    @media (max-width: 1300px) {
      font-size: 220px;
      line-height: 200px;
    }
    @media (max-width: 1085px) {
      font-size: 180px;
      line-height: 160px;
    }
    @media (max-width: 885px) {
      font-size: 130px;
      line-height: 130px;
    }
    @media (max-width: 664px) {
      font-size: 100px;
      line-height: 100px;
    }
    @media (max-width: 520px) {
      font-size: 70px;
      line-height: 70px;
    }
  }

  .help-you {
    position: relative;
    color: #a8cf45;
    text-align: right;

    img {
      width: 130px;
      position: absolute;
      top: 10px;
      left: 15%;
      @media (max-width: 1200px) {
        width: 100px;
      }
      @media (max-width: 1024px) {
        width: 70px;
      }
      @media (max-width: 625px) {
        width: 40px;
      }
      @media (max-width: 395px) {
        width: 30px;
        left: 5%;
      }
    }
  }
  .services {
    text-align: right;
  }
`;
