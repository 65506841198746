import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import HeaderTab from "../animations/HeaderTab";
import { AppLogo } from "../utils/Images";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header({ servicesRef, projectRef, contactRef }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [textColor, setTextColor] = useState("rgb(255, 255, 255)");

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position <= 89) {
      setScrollPosition(position);
      let value = 255 - position * 2.6;
      setTextColor(`rgb(${value},${value}, ${value})`);
    } else {
      setScrollPosition(89);
      setTextColor("rgb(0,0, 0)");
    }
  };

  const handleNavigation = (ref) => {
    if (location?.pathname === "/") {
      ref?.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    navigate("/");
    window.scrollTo(0, 0, { behavior: "smooth" });
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderWrapper>
      <FirstRow>
        <img src={AppLogo} alt="" />
      </FirstRow>
      <SectionRow height={scrollPosition} color={textColor}>
        <ul>
          <li>
            <HeaderTab
              tittle={"Services"}
              baseColor={textColor}
              handleClick={() => handleNavigation(servicesRef)}
            />
          </li>
          <li>
            <HeaderTab
              tittle={"Project"}
              baseColor={textColor}
              handleClick={() => handleNavigation(projectRef)}
            />
          </li>
          <li>
            <HeaderTab
              tittle={"Contact"}
              baseColor={textColor}
              handleClick={() => handleNavigation(contactRef)}
            />
          </li>
        </ul>
      </SectionRow>
    </HeaderWrapper>
  );
}

const SectionRow = styled.div`
  width: 66.7%;
  height: ${({ height }) => `${height}px`};
  background: #fff;
  color: ${({ color }) => `${color}`};
  transition: color 0.6s, height 0.6s;

  ul {
    height: 89px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 10px;
    gap: 20px;

    li {
      list-style: none;
      // padding: 0 20px;
    }
  }

  @media (max-width: 743px) {
    height: 74px !important;
    ul {
      height: 74px !important;
    }
    li div {
      color: rgb(0 0 0) !important;
    }
  }
  @media (max-width: 668px) {
    display: none;
  }
`;

const FirstRow = styled.div`
  width: 33.3%;
  height: 89px;
  background: #fff;
  display: flex;
  justify-content: start;
  align-items: center;

  img {
    padding: 0 20px;
    height: 60px;
    opacity: 1;
    animation-name: logo;
    animation-duration: 2.5s;

    @keyframes logo {
      50% {
        opacity: 0;
        transform: rotateY(0deg);
      }
      100% {
        opacity: 1;
        transform: rotateY(360deg);
      }
    }
  }

  @media (max-width: 743px) {
    width: 220px;
    height: 74px;
    img {
      height: 40px;
    }
  }
`;

const HeaderWrapper = styled.div`
  width: 88%;
  height: 74px;
  display: flex;
  position: fixed;
  z-index: 9;
`;
