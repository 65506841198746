import React from "react";
import styled from "styled-components";

export default function HeaderTab({ tittle, baseColor, handleClick }) {
  const onMouseMove = (e) => {
    let x = e.nativeEvent.offsetX;
    let y = e.nativeEvent.offsetY;
    let textWidth = e.currentTarget.clientWidth;
    let textHeight = e.currentTarget.clientHeight;
    let transX = x - textWidth / 2;
    let transY = y - textHeight / 2;
    e.currentTarget.style.transform = `translate(${transX}px, ${transY}px)`;
  };

  const onMouseLeave = (e) => {
    e.currentTarget.style.transform = "translate(0, 0)";
  };

  return (
    <ButtonStyleHover baseColor={baseColor}  >
      <div className="hover-btn-container" onClick={handleClick}>
        <p
          className="btn-text"
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
        >
          {tittle}
        </p>
      </div>
    </ButtonStyleHover>
  );
}

const ButtonStyleHover = styled.div`
  width: fit-content;
  .hover-btn-container {
    position: relative;
    height: 52px;
    padding: 0 40px;
    border-radius: 40px;
    cursor: pointer;
    transition: transform 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ baseColor }) => baseColor};

    @media(max-width:940px){
      padding: 0 20px;
    }

    @media(max-width:743px){
      height: 38px;
    }

  }

  .hover-btn-container:hover {
    border:  ${({ baseColor }) => `2px ${baseColor} solid`};
    background-color: tranparent;
    color: ${({ baseColor }) => baseColor};
    box-shadow: 0px 0px 3px 1px #f3f6fc;
    transition: border 0.4s linear;
    transform: scale(1.02);
  }

  .btn-text {
    width: fit-content;
    height: 52px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 13px;
    transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width:940px){
      font-size: 10px;
    }
    @media(max-width:743px){
      height: 38px;
    }
  }
`;
