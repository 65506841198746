import React from "react";
import styled from "styled-components";
import { AppContainer } from "../Styles/GobalStyle";
import MotionButton from "../animations/MotionButton";
import { Rate } from "antd";

export default function OurClients() {
  const RatingData = [
    {
      message:
        "Very smooth working experience over a 6-month period. They communicated well with me and really understood my requirements and worked on them. Importantly, they did not overpromise on features and were very transparent on their deliverables would be. Very happy with their overall work.",
      clientName: "David Ratner",
      country: "Singapore",
      rate: 5,
    },

    {
      message:
        "Although the workflow goes up and down I found them very professional to carry this part of my business. Make Swaroop your technology partner.",
      clientName: "Hassan Jaber",
      country: "Kuwait",
      rate: 3,
    },

    {
      message:
        "We are really happy with the project! They delivered a great product with an affordable price in a perfect time frame.",
      clientName: "Bruno",
      country: "Australia",
      rate: 4,
    },

    {
      message:
        "It was a great Experience will work with the team again for sure.",
      clientName: "Thony",
      country: "USA",
      rate: 5,
    },
  ];
  return (
    <OurClientsWrapper>
      <AppContainer>
        <FirstSection>
          <section>
            <p>Our Happy Clients</p>
            <h1>What Our Clients Say About Us</h1>
          </section>
          {/* <div className="position-motionBtn">
            <MotionButton
              tittle={"View all review"}
              baseColor={"#ffffff"}
              backgroundColor={"#000000"}
              hoverColor={"#e2e7f1"}
              texthoverColor={"#090b19"}
              handleClicked={()=>console.log('I am clicked')}
            />
          </div> */}
        </FirstSection>

        <ReviewRatingWrapper>
          {RatingData?.map((ele) => (
            <ReviewRatingBox>
              <div className="hoverClass"></div>
              <ContentBox>
                <p>{ele?.message}</p>
                <div>
                  <h3>{ele?.clientName}</h3>
                  <h5>{ele?.country}</h5>
                  <Rate
                    disabled
                    defaultValue={ele?.rate}
                    style={{ color: "#ffb500" }}
                  />
                </div>
              </ContentBox>
            </ReviewRatingBox>
          ))}
        </ReviewRatingWrapper>
      </AppContainer>
    </OurClientsWrapper>
  );
}

const ContentBox = styled.div`
  //   inset: 0;
  //   padding: 38px 19px;
  
  position: relative;
  z-index: 8;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:38px;

  &:hover {
    p,
    h3,
    h5 {
      transform: scale(0.9);
      transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
      text-shadow: 1px 1px #f3f6fc;
    }

    .ant-rate {
      transform: scale(1.2);
      transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
      margin: 0 0 0 30px;
    }
  }

  p {
    color: #6e7488;
    font-size: 17px;
    line-height: 1.6em;
    padding: 10px 20px;
  }

  >div {
    padding: 20px;
  }
  h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: -0.03em;
  }

  h5 {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: -0.03em;
  }
`;

const ReviewRatingBox = styled.div`
  width: 100%;
  //   height: 380px;
  border: 1px solid #f3f6fc;
  cursor: pointer;
  position: relative;

  .hoverClass {
    display: none;
    z-index: 6;
  }

  &:hover {
    transform: scale(1.06);
    transition: transform 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    box-shadow: 0 0 10px #f3f6fc;

    .hoverClass {
      display: unset;
      position: absolute;
      inset: 0;
      z-index: 6;
      background: rgb(243, 246, 252);
      animation-name: hoverClassAnimation;
      animation-duration: 0.8s;
      @keyframes hoverClassAnimation {
        0% {
          width: 0;
          height: 0;
          border-radius: 0;
          background: rgb(243, 246, 252);
          z-index: 6;
        }
        30% {
          border-radius: 0 0 50% 0;
        }
        50% {
          border-radius: 0 0 100% 0;
        }
        70% {
          border-radius: 0 0 50% 0;
        }
        100% {
          width: 100%;
          //   height: 380px;
          border-radius: 0;
          background: rgb(243, 246, 252);
          z-index: 6;
        }
      }
    }
  }
`;

const ReviewRatingWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 36px;
  @media(max-width:780px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media(max-width:550px){
    grid-template-columns: repeat(1, 1fr);
  }
`;

const FirstSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      color: #6e7488;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
    h1 {
      font-size: 48px;
      line-height: 1.2em;
      font-weight: 600;
      letter-spacing: -0.03em;
      @media(max-width:550px){
        font-size: 36px;
      }
    }
    @media (max-width: 450px) {
      text-align: center;
    }
  }
`;

const OurClientsWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 80px 0;
`;
