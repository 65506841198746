import React from "react";
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
} from "react-router-dom";
import { publicPaths } from "./PublicPaths";
import PrivateLayout from "../layout/PrivateLayout";
import { privatePaths } from "./PrivatePaths";

function PublicRoute({ isAuthenticated }) {
    if (isAuthenticated) {
        return <Navigate to="/private/dashboard" replace />;
    }
    return <Outlet />;
}

// This function is used for Authentication when user's credentials will be invalid.
function PrivateRoute({ isAuthenticated }) {
    if (isAuthenticated == null) return <Navigate to="/" />;
    return <Outlet />;
}

export default function AppRoutes() {
    const token = null;
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PublicRoute isAuthenticated={token} />}>
                    {publicPaths?.map((routes, index) => (
                        <Route key={index} path={routes.path} element={routes.component} />
                    ))}
                </Route>

                <Route element={<PrivateRoute isAuthenticated={token} />}>
                    <Route path="private" element={<PrivateLayout />}>
                        {privatePaths?.map((routes, index) => (
                            <Route key={index} path={routes.path} element={routes.component} />
                        ))}
                    </Route>
                </Route>

            </Routes>
        </BrowserRouter>
    );
}
