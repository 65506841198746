import React from "react";
import styled from "styled-components";
import CredentialBox from "./CredentialBox";

export default function Credentials() {
    return (
        <CredentialsWrapper>
            <SubHeader>
                <div>Create</div>
            </SubHeader>

            <section>
                <CredentialBox />
                <CredentialBox />
                <CredentialBox />
                <CredentialBox />
                <CredentialBox />
                <CredentialBox />
                <CredentialBox />
                <CredentialBox />
            </section>
        </CredentialsWrapper>
    );
};

const SubHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;

  div {
    width: auto;
    height: 44px;
    gap: 16px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0 22px;
    cursor: pointer;
    color: #f16136;
    margin: 16px 0;
    background: rgb(241 97 54 / 16%);
    box-shadow: 11px 5px 20px -10px #6625008f;
    transition: border 4s;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;

    &:hover {
    //   border: 0.5px solid #;
      box-shadow: 11px 5px 20px -10px #f16136;
      
    }
  }
`;

const CredentialsWrapper = styled.div`
  padding: 20px;

  section {
    display:grid;
    grid-template-columns: repeat(2,1fr);
    gap: 20px ;
  }
`;
