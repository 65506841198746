import axios from "axios";

// const EndPoint = process.env.REACT_APP_BASEURL + process.env.REACT_APP_API_VERSION;
const EndPoint = "https://api.swaroopitsolutions.com/"

const Api = axios.create({
  timeout: 1000000,
  baseURL: EndPoint,
});

Api.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
Api.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

Api.interceptors.request.use(
  (config) => {
    // const token = store.getState().userAuth.data?.token;

    // config.headers = {
    //   Authorization: `Bearer ${token}`,
    // };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Api;
