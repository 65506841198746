import { useEffect } from "react";
import AppRoutes from "./routes/AppRoutes";
import { securityChecks } from "./utils/ConstantFunction";

function App() {

  useEffect(() => {
    const isLocalhost = window.location.hostname === 'localhost';
    if (!isLocalhost) securityChecks();
  }, []);

  return <AppRoutes />;
}

export default App;