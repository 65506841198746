import React from "react";
import styled from "styled-components";
import Bowser from "bowser";

export default function MotionButton({
  tittle,
  baseColor,
  hoverColor,
  backgroundColor,
  texthoverColor,
  handleClicked,
}) {
  let checkingplatform = Bowser?.parse(window?.navigator?.userAgent)?.platform?.type;

  const onMouseMove = (e) => {
    if (checkingplatform === "desktop") {
      let x = e.nativeEvent.offsetX;
      let y = e.nativeEvent.offsetY;
      let textWidth = e.currentTarget.clientWidth;
      let textHeight = e.currentTarget.clientHeight;
      let transX = x - textWidth / 2;
      let transY = y - textHeight / 2;
      e.currentTarget.style.transform = `translate(${transX}px, ${transY}px)`;
    } else {
      e.currentTarget.style.transform = "translate(0, 0)";
    }
  };

  const onMouseLeave = (e) => {
    e.currentTarget.style.transform = "translate(0, 0)";
  };

  return (
    <ButtonStyleHover
      baseColor={baseColor}
      hoverColor={hoverColor}
      backgroundColor={backgroundColor}
      texthoverColor={texthoverColor}
      onClick={() => handleClicked()}
    >
      <div className={"hover-btn-container"}>
        <p
          className="btn-text"
          onMouseLeave={onMouseLeave}
          onMouseMove={onMouseMove}
        >
          {tittle}
        </p>
      </div>
    </ButtonStyleHover>
  );
}

const ButtonStyleHover = styled.div`
  width: fit-content;
  .hover-btn-container {
    position: relative;
    width: 200px;
    height: 52px;
    padding: 0 20px;
    border-radius: 40px;
    border: 2px rgba(255, 255, 255, 0.75) solid;
    border: ${({ backgroundColor }) => `2px ${backgroundColor} solid`};
    cursor: pointer;
    transition: transform 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ baseColor }) => baseColor};
    background: ${({ backgroundColor }) => backgroundColor};
  }

  .hover-btn-container:hover {
    border-color: ${({ hoverColor }) => hoverColor};
    background-color: ${({ hoverColor }) => hoverColor};
    color: ${({ texthoverColor }) => texthoverColor};
    box-shadow: ;
    box-shadow: ${({ hoverColor }) => `0px 0px 3px 1px ${hoverColor}`};
    transition: background-color 0.4s linear;
    transform: scale(1.02);
  }

  .btn-text {
    width: fit-content;
    height: 52px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 12px;
    transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
