import Login from "../app/auth/Login";
import LandingLayout from "../layout/LandingLayout";
import PortfolioLayout from "../layout/PortfolioLayout";

export const publicPaths = [
    {
        path: "/",
        component: <LandingLayout />
    },
    {
        path: "/admin-login",
        component: <Login />
    },
    {
        path: "/portfolio",
        component: <PortfolioLayout />
    },
]