import { folder, imageBaseUrl, videoFolder } from "./Path";

let LandingBackground = `${imageBaseUrl + folder}landingbackground.jpg?alt=media&token=609846df-e79f-4cda-86be-6c918a65b83e`;
let AppLogo = `${imageBaseUrl + folder}applogo.png?alt=media&token=0c73b28c-3897-4b6c-aad2-15dff085a1be`;
let AboutUsBackground = `${imageBaseUrl + folder}aboutus.png?alt=media&token=c815c4d1-6257-4710-a82e-44990113b49f`;
let CEO = `${imageBaseUrl + folder}CEO.png?alt=media&token=401b44a8-11b3-4dd5-ad8c-471d222490db`;
let Swaroop = `${imageBaseUrl + folder}applogo2.png?alt=media&token=2275a44c-b6f8-4c0d-8e3f-4829d2143166`;
let LetsTalk = `${imageBaseUrl + folder}letstalks.jpg?alt=media&token=544241df-384f-4a89-8b31-1a7080063009`;
let Intro = `${imageBaseUrl + folder}intro.png?alt=media&token=79147e50-e8cb-455c-86f9-1221aa44372c`;
let FooterLogo = `${imageBaseUrl + folder}applogo2.png?alt=media&token=2275a44c-b6f8-4c0d-8e3f-4829d2143166`;
let Github = `${imageBaseUrl + folder}github-logo.png?alt=media&token=14c4633c-5080-4493-85a6-c33ef458dfad`;
let BgShade = `${imageBaseUrl + folder}bgshade.png?alt=media&token=d6539caa-1fd7-4d51-822b-2773f2a23e21`;
let AvatarEmoji1 = `${imageBaseUrl + folder}avatarEmoji1.png?alt=media&token=224f5eae-cd7c-4417-99d2-f3b26a6ed86c`;
let AvatarEmoji2 = `${imageBaseUrl + folder}avatarEmoji2.png?alt=media&token=bd1cfba1-67cb-49e1-8753-f7d6303c4702`;
let DatingApp = `${imageBaseUrl + folder}datingApp.png?alt=media&token=4fa0288a-c92c-4d8e-80c9-665d2d979322`;
let ShotApp = `${imageBaseUrl + folder}shotApp.png?alt=media&token=7aa32f62-b14d-420d-a8c3-62ff77ad9b58`;
let HealthApp1 = `${imageBaseUrl + folder}healthApp1.png?alt=media&token=54a50308-8fc7-464a-b97b-ce3574476178`;
let ResturentApp = `${imageBaseUrl + folder}resturentApp.png?alt=media&token=4292c442-144b-4951-89d5-a61195e8d570`;
let RealEstateApp = `${imageBaseUrl + folder}EstateApp.png?alt=media&token=0073d5ab-d6cb-4ae8-a50b-5469f5067a84`;
let AiChatBotthumbnail = `${imageBaseUrl + folder}AiChatThumbnail.png?alt=media&token=fef9d60b-7e02-4a91-8019-18c5b2ca0f88`;
let AiChatBot = `${imageBaseUrl + videoFolder}Aibot.mp4?alt=media&token=566bcda9-90a4-4e4a-b9fb-360b43a46216`;

export {
    AiChatBot,
    AiChatBotthumbnail,
    RealEstateApp,
    ResturentApp,
    HealthApp1,
    ShotApp,
    DatingApp,
    AvatarEmoji1,
    AvatarEmoji2,
    BgShade,
    Github,
    FooterLogo,
    Intro,
    LetsTalk,
    Swaroop,
    CEO,
    LandingBackground,
    AppLogo,
    AboutUsBackground,
};
